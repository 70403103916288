import React from 'react';
import '../styles/banner.css';

export default function banner() {
    return (
        <div className="banner">
            <div className="bannerTitle">
                <p>IN PERSON AND VIRTUAL THERAPY</p>
            </div>
        </div>
    )
}